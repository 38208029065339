export const translations = {
  "login-ciao": {
    "it-IT": "Ciao,",
    "en-US": "Hello,",
  },
  "login-bentornato": {
    "it-IT": "Bentornato",
    "en-US": "Welcome Back",
  },
  "login-pass-dimenticata": {
    "it-IT": "Password dimenticata?",
    "en-US": "Forgot password?",
  },
  "login-accedi": {
    "it-IT": "Accedi",
    "en-US": "Sign-in",
  },
  "login-snackbar": {
    "it-IT": "Per favore, accedi.",
    "en-US": "Please, log in.",
  },
  "login-nuovo-account": {
    "it-IT": "Non hai ancora un account?",
    "en-US": "Don't you have an account yet?",
  },
  "login-registrati": {
    "it-IT": "Registrati",
    "en-US": "Register",
  },
  "google-login-button": {
    "it-IT": "Accedi con Google",
    "en-US": "Sign in with Google",
  },
  "google-login-error": {
    "it-IT": "Errore inizializione Google login",
    "en-US": "Error initializing Google login",
  },
  "login-401": {
    "it-IT": "Email o password incorretti",
    "en-US": "Wrong email or password",
  },
  "login-400": {
    "it-IT": "Credenziali non corrette o inesistenti",
    "en-US": "Invalid credentials",
  },
  "helpertext-email": {
    "it-IT": "Per favore, inserisci una email valida.",
    "en-US": "Please enter a valid email.",
  },
  "helpertext-phone": {
    "it-IT": "Numero di cellulare non valido",
    "en-US": "Invalid phone nubmer",
  },
  "helpertext-link": {
    "it-IT": "Questo link sembra non essere valido",
    "en-US": "This link seems to be invalid",
  },
  // SIGN UP
  "signup-ciao": {
    "it-IT": "Ciao,",
    "en-US": "Hello,",
  },
  "signup-benvenuto": {
    "it-IT": "Benvenuto",
    "en-US": "Welcome",
  },
  "signup-hai-account": {
    "it-IT": "Hai già un account?",
    "en-US": "Do you already have an account?",
  },
  "signup-accedi": {
    "it-IT": "Accedi",
    "en-US": "Sign-in",
  },
  "signup-inizia": {
    "it-IT": "Inizia",
    "en-US": "Start",
  },
  "signup-help": {
    "it-IT":
      "Il PIN è un numero di 4 cifre utilizzato per la creazione del profilo. Lo trovi nella brochure che ti è stata consegnata insieme alla card.",
    "en-US":
      "The PIN is a 4-digit number used for profile creation. You can find it in the brochure that was provided to you along with the card.",
  },
  "signup-pin": {
    "it-IT": "Inserisci il PIN di 4 cifre",
    "en-US": "Enter the 4-digit PIN",
  },
  "email-non-valida": {
    "it-IT": "Per favore, inserisci una email valida. ",
    "en-US": "Please enter a valid email. ",
  },
  "signup-password-non-uguali": {
    "it-IT": "Le Password non sono uguali. ",
    "en-US": "Passwords do not match. ",
  },
  password2: {
    "it-IT": "Conferma Password",
    "en-US": "Confirm Password",
  },
  "password-non-valida": {
    "it-IT": "Per favore, inserisci una password valida. ",
    "en-US": "Please enter a valid password. ",
  },

  "password-lunghezza-minima": {
    "it-IT": "Una lunghezza minima di otto caratteri",
    "en-US": "At least eight characters in length",
  },
  "password-lettera-maiuscola": {
    "it-IT": "Almeno una lettera maiuscola",
    "en-US": "At least one uppercase letter",
  },
  "password-lettera-minuscola": {
    "it-IT": "Almeno una lettera minuscola",
    "en-US": "At least one lowercase letter",
  },
  "password-carattere-numeric": {
    "it-IT": "Almeno un carattere numerico",
    "en-US": "At least one numeric character",
  },
  "password-requisiti-introduzione": {
    "it-IT": "Crea una password con:",
    "en-US": "Create a password with:",
  },
  "register-401": {
    "it-IT": "Email o password incorretti",
    "en-US": "Wrong email or password",
  },
  "register-400": {
    "it-IT": "Credenziali non corrette o inesistenti",
    "en-US": "Invalid credentials",
  },
  "terms-conditions-1": {
    "it-IT": "Registrandoti affermi di aver letto e accettato ",
    "en-US": "By registering, you accept ",
  },
  "terms-conditions-2": {
    "it-IT": "Condizioni",
    "en-US": "Conditions",
  },
  "terms-conditions-3": {
    "it-IT": " e ",
    "en-US": " and ",
  },
  "terms-conditions-4": {
    "it-IT": "Informativa sulla Privacy",
    "en-US": "Privacy Policy",
  },
  //RECOVER PASSWORD
  "recpass-Recupero": {
    "it-IT": "Recupero",
    "en-US": "Retrive",
  },
  "recpass-Password": {
    "it-IT": "Password",
    "en-US": "Password",
  },
  "recpass-controlla-email": {
    "it-IT": "Ottimo, ora controlla la tua email",
    "en-US": "Good, now check your email",
  },
  "recpass-otp": {
    "it-IT":
      "Inserisci la tua email per ricevere il link per cambiare la password",
    "en-US": "Enter your email to receive the link to change your password",
  },
  "recpass-Invia": {
    "it-IT": "Invia",
    "en-US": "Send",
  },

  //ACCOUNT VERIFICATION
  "check-verifica": {
    "it-IT": "Verifica",
    "en-US": "Verify",
  },
  "check-account": {
    "it-IT": "Account",
    "en-US": "Account",
  },
  "check-otp": {
    "it-IT": "Inserisci il codice OTP ricevuto alla mail:",
    "en-US": "Enter the OTP code received at the email:",
  },
  "check-controlla-email": {
    "it-IT": "Controlla la tua email",
    "en-US": "Check your email",
  },
  "check-Invia": {
    "it-IT": "Invia",
    "en-US": "Send",
  },
  "check-hai-account": {
    "it-IT": "Hai già un account?",
    "en-US": "Do you already have an account?",
  },
  "check-accedi": {
    "it-IT": "Accedi",
    "en-US": "Sign-in",
  },

  //CREATE NEW PASSWORD
  "newpass-inserisci": {
    "it-IT": "Inserisci una nuova ",
    "en-US": "Enter a new ",
  },
  "newpass-Password": {
    "it-IT": "Password",
    "en-US": "Password",
  },
  "newpass-cambiata1": {
    "it-IT": "La tua password è stata",
    "en-US": "Your password has been",
  },
  "newpass-cambiata2": {
    "it-IT": "cambiata con successo!",
    "en-US": "successfully changed!",
  },
  "newpass-send1": {
    "it-IT": "Controlla La ",
    "en-US": "Check Your ",
  },
  "newpass-send2": {
    "it-IT": "email",
    "en-US": "Email",
  },
  "newpass-help1": {
    "it-IT": "Controlla il tuo indirizzo email ",
    "en-US": "Please check the email address ",
  },
  "newpass-help2": {
    "it-IT": " per istruzioni per resettare la password.",
    "en-US": " for instructions to reset your password.",
  },
  "newpass-match": {
    "it-IT": "Le password non combaciano!",
    "en-US": "Passwords don't match!",
  },
  "newpass-inizia": {
    "it-IT": "Cambia Password",
    "en-US": "Change Password",
  },
  "newpass-accedi": {
    "it-IT": "Accedi",
    "en-US": "Log-in",
  },
  "newpass-close": {
    "it-IT": "Chiudi questa ",
    "en-US": "Log-in",
  },

  //PROFILE SET UP
  "psu-salta": {
    "it-IT": "Salta",
    "en-US": "Skip",
  },
  "psu-title": {
    "it-IT": "Scegli la tua immagine profilo",
    "en-US": "Choose your profile image",
  },
  "psu-carica": {
    "it-IT": "Carica",
    "en-US": "Upload",
  },
  "psu-non-ora": {
    "it-IT": "Non ora",
    "en-US": "Not now",
  },
  "psu-complimenti": {
    "it-IT": "Complimenti! Ha scelto una bellissima immagine",
    "en-US": "Congratulations! You have chosen a beautiful image",
  },
  "psu-domanda": {
    "it-IT": "Sei sicuro di non voler caricare un'immagine?",
    "en-US": "Are you sure you don't want to upload an image?",
  },
  "psu-prossimo": {
    "it-IT": "Prossimo",
    "en-US": "Next",
  },
  "psu-cambia": {
    "it-IT": "Cambia Immagine",
    "en-US": "Change Image",
  },
  "psu-dati": {
    "it-IT": "Inserisci i tuoi dati",
    "en-US": "Please enter your data",
  },
  "psu-l1": {
    "it-IT": "Nome",
    "en-US": "Name",
  },
  "psu-l2": {
    "it-IT": "Cognome",
    "en-US": "Surname",
  },
  "psu-username-utilizzato": {
    "it-IT": "Questo username è già in uso.",
    "en-US": "This username is already taken.",
  },
  "psu-username-lenght": {
    "it-IT": "L'username deve essere minimo di 5 caratteri.",
    "en-US": "The username must be at least 5 characters long.",
  },
  "psu-username-invalid-characters": {
    "it-IT":
      "L'username può contenere solo caratteri alfanumerici e trattini bassi ('_').",
    "en-US":
      "The username can only contain alphanumeric characters and underscores ('_').",
  },
  "psu-username-list-characters": {
    "it-IT": "Elenco caratteri non consentiti: ",
    "en-US": "List of invalid characters: ",
  },
  "psu-username-valid": {
    "it-IT": "L'username verrà utilizzato per creare il tuo link nella forma: ",
    "en-US": "Your username will be used to create your link in the form of: ",
  },
  "psu-username-numeric": {
    "it-IT": "L'username non può essere composto esclusivamente da numeri.",
    "en-US": "Username cannot be purely numeric.",
  },
  "psu-l3": {
    "it-IT": "Username",
    "en-US": "Username",
  },
  "psu-l4": {
    "it-IT": "Biografia",
    "en-US": "Biography",
  },
  "psu-fine": {
    "it-IT": "Fine",
    "en-US": "Finish",
  },
  "psu-completata": {
    "it-IT": "Configurazione iniziale completata",
    "en-US": "Initial setup completed",
  },
  "profile-setup-unfinished": {
    "it-IT": "Completa la configurazione iniziale del tuo profilo",
    "en-US": "Complete the initial setup of your profile",
  },
  "psu-inizia": {
    "it-IT": "Inizia",
    "en-US": "Start",
  },
  "psu-continua": {
    "it-IT": "Aziona il video per proseguire",
    "en-US": "Press the video to continue",
  },
  //BOTTOM DRAWER

  "botdrw-logout": {
    "it-IT": "Esci",
    "en-US": "Logout",
  },
  "botdrw-share": {
    "it-IT": "Condividi",
    "en-US": "Share",
  },
  "botdrw-edit": {
    "it-IT": "Modifica Profilo",
    "en-US": "Edit Profile",
  },
  "botdrw-preview": {
    "it-IT": "Anteprima Profilo",
    "en-US": "Profile Preview",
  },
  "botdrw-settings": {
    "it-IT": "Impostazioni",
    "en-US": "Settings",
  },
  "botdrw-meralink": {
    "it-IT": "Crea il tuo Meralink",
    "en-US": "Create Your Meralink",
  },
  "botdrw-carta": {
    "it-IT": "Ottieni la tua Card",
    "en-US": "Get your card",
  },
  "botdrw-registrati": {
    "it-IT": "Registrati Ora",
    "en-US": "Signup Now",
  },
  "botdrw-login": {
    "it-IT": "Accedi",
    "en-US": "Login",
  },
  "botdrw-assistenza": {
    "it-IT": "Ricevi assistenza",
    "en-US": "Get assistance",
  },

  //SETTINGS OPTIONS
  "setting-title": {
    "it-IT": "Impostazioni",
    "en-US": "Settings",
  },
  "setting-lingua": {
    "it-IT": "Cambia lingua",
    "en-US": "Change language",
  },
  "setting-set-profile": {
    "it-IT": "Modifica Profilo",
    "en-US": "Edit Profile",
  },

  //SHARE OPTIONS
  "share-copia": {
    "it-IT": "Copia",
    "en-US": "Copy",
  },
  "share-condividi": {
    "it-IT": "Condividi su ",
    "en-US": "Share with ",
  },
  "share-condividi2": {
    "it-IT": "Condividi tramite ",
    "en-US": "Share with ",
  },
  "share-piu-opzioni": {
    "it-IT": "Più opzioni",
    "en-US": "More options",
  },

  //BUTTONS MENU
  "btsmenu-title": {
    "it-IT": "Cosa vuoi inserire?",
    "en-US": "What do you want to insert?",
  },
  "btsmenu-contact": {
    "it-IT": "Scheda contatto",
    "en-US": "Contact",
  },
  "btsmenu-link": {
    "it-IT": "Link / Sito web",
    "en-US": "Link / Website",
  },
  "btsmenu-social": {
    "it-IT": "Social",
    "en-US": "Social",
  },
  "btsmenu-pdf": {
    "it-IT": "PDF",
    "en-US": "PDF",
  },
  "btsmenu-video": {
    "it-IT": "Video",
    "en-US": "Video",
  },
  "btsmenu-Recensione": {
    "it-IT": "Recensione",
    "en-US": "Review",
  },

  //HEADER INFO BUTTONS
  "header-title": {
    "it-IT": "Titolo Pulsante",
    "en-US": "Button Title",
  },
  "header-style": {
    "it-IT": "Stile",
    "en-US": "Style",
  },
  "header-show": {
    "it-IT": "Visibile",
    "en-US": "Visible",
  },
  "header-style-button": {
    "it-IT": "Pulsante",
    "en-US": "Button",
  },
  "header-style-icon": {
    "it-IT": "Icona",
    "en-US": "Icon",
  },
  "header-icon": {
    "it-IT": "Icona",
    "en-US": "Icon",
  },
  "select-group1": {
    "it-IT": "Le più utilizzate",
    "en-US": "The most commonly used",
  },
  "select-group2": {
    "it-IT": "Social Media",
    "en-US": "Social Media Icons",
  },
  // SEARCH SELECT
  search_select: {
    loading: {
      "it-IT": "Caricamento...",
      "en-US": "Loading...",
    },
    no_icons: {
      "it-IT": "Nessuna icona",
      "en-US": "No icons",
    },
  },
  //WEBSITE INFO
  "website-url": {
    "it-IT": "Url del sito web",
    "en-US": "Website Url",
  },
  //PDF INFO
  "pdf-title": {
    "it-IT": "Carica documento",
    "en-US": "Upload document",
  },
  "pdf-scarica": {
    "it-IT": "Scarica il tuo file",
    "en-US": "Download your file",
  },
  "pdf-carica": {
    "it-IT": "Scegli il tuo file",
    "en-US": "Choose your file",
  },
  "pdf-cambia": {
    "it-IT": "Cambia il tuo file",
    "en-US": "Change your file",
  },
  "pdf-salva": {
    "it-IT": "Salva",
    "en-US": "Save",
  },

  //CONTACT INFO

  "contact-title": {
    "it-IT": "Scheda contatto",
    "en-US": "Contact form",
  },
  "contact-name": {
    "it-IT": "Nome contatto",
    "en-US": "Contact Name",
  },
  "contact-surname": {
    "it-IT": "Cognome contatto",
    "en-US": "Contact Surname",
  },
  "contact-attivita": {
    "it-IT": "Nome attività",
    "en-US": "Business Name",
  },
  "contact-ruolo": {
    "it-IT": "Ruolo",
    "en-US": "Role",
  },
  "contact-tipo": {
    "it-IT": "Tipo",
    "en-US": "Type",
  },
  "contact-mail": {
    "it-IT": "Email",
    "en-US": "Email",
  },
  "contact-cell": {
    "it-IT": "Numero di cellulare",
    "en-US": "Cellphone Number",
  },
  "contact-indirizzo": {
    "it-IT": "Indirizzo",
    "en-US": "Address",
  },
  "contact-place": {
    "it-IT": "Indirizzo 2",
    "en-US": "Place",
  },
  "contact-house": {
    "it-IT": "Civico",
    "en-US": "House Number",
  },
  "contact-iva": {
    "it-IT": "Partita IVA",
    "en-US": "VAT Number",
  },
  "contact-sdi": {
    "it-IT": "SDI",
    "en-US": "SDI",
  },
  "contact-lavoro": {
    "it-IT": "Lavoro",
    "en-US": "Work",
  },
  "contact-personale": {
    "it-IT": "Personale",
    "en-US": "Personal",
  },
  "contact-pec": {
    "it-IT": "PEC",
    "en-US": "PEC",
  },
  "contact-fisso": {
    "it-IT": "Fisso",
    "en-US": "Landline",
  },
  "contact-mobile": {
    "it-IT": "Mobile",
    "en-US": "Mobile",
  },
  "contact-casa": {
    "it-IT": "Casa",
    "en-US": "Home",
  },
  "contact-operativa": {
    "it-IT": "Sede operativa",
    "en-US": "Operational Office",
  },
  "contact-legale": {
    "it-IT": "Sede Legale",
    "en-US": "Legal Office",
  },
  "contact-code-country": {
    "it-IT": "Codice Paese",
    "en-US": "Country Code",
  },
  "contact-country": {
    "it-IT": "Paese",
    "en-US": "Country",
  },
  "contact-province": {
    "it-IT": "Regione",
    "en-US": "Province",
  },
  "contact-city": {
    "it-IT": "Città",
    "en-US": "City",
  },
  "contact-prefix": {
    "it-IT": "Prefisso",
    "en-US": "Prefix",
  },
  "contact-cap": {
    "it-IT": "CAP",
    "en-US": "Zip Code",
  },
  salva: {
    "it-IT": "Salva",
    "en-US": "Save",
  },

  //REVIEW INFO
  helpertext: {
    "it-IT":
      "Incolla qui sopra il link di dove vuoi che i tuoi clienti lascino una recensione.",
    "en-US":
      "Paste the link above where you want your customers to leave a review.",
  },

  "helpertext-tutorial": {
    "it-IT": "Clicca qua per guardare un breve tutorial",
    "en-US": "Click here to watch a quick tutorial",
  },
  "helpertext-onlyone": {
    "it-IT":
      "Nel caso in cui utilizzerai Hellolink con solamente un bottone recensione, reindirizzerai l'utente che scriverà direttamente la recensione.",
    "en-US":
      "In case you use Hellolink with only a review button, you will redirect the user who will write the review directly.",
  },
  "rev-feed": {
    "it-IT": "Lascia il tuo feedback",
    "en-US": "Your feedback",
  },
  "rev-service": {
    "it-IT": "Valuta il nostro servizio",
    "en-US": "Evaluate our service",
  },
  //UNAUTHORIZED PAGE
  ops: {
    "it-IT": "Oops! Sembra che tu sia nel posto sbagliato.",
    "en-US": "Oops! Looks like you're in the wrong place.",
  },
  ops2: {
    "it-IT":
      "Forse volevi andare altrove? Questa strada è solo per gli eletti!",
    "en-US":
      "Perhaps you wanted to go somewhere else? This path is only for the chosen ones!",
  },
  ops3: {
    "it-IT": "Torna indietro",
    "en-US": "Go back",
  },
  //404

  "not-found-title": {
    "it-IT": "Utente Non Trovato",
    "en-US": "User Not Found",
  },
  "not-found-message": {
    "it-IT":
      "Siamo spiacenti, non riusciamo a trovare l'utente che stai cercando.",
    "en-US": "We're sorry, we can't find the user you are looking for.",
  },
  "not-found-button": {
    "it-IT": "Torna alla Homepage",
    "en-US": "Return to Homepage",
  },

  //PIN ATTIVATION
  "pin-attiva": {
    "it-IT": "Attiva la tua",
    "en-US": "Active your",
  },
  "pin-card": {
    "it-IT": "Carta NFC",
    "en-US": "NFC Card",
  },
  "pin-code": {
    "it-IT": "Codice NFC",
    "en-US": "NFC Code",
  },
  "pin-inserisci": {
    "it-IT": "Inserisci il PIN",
    "en-US": "Enter PIN",
  },
  //MODIFY-USER
  "mod-user-button": {
    "it-IT": "Torna alla Homepage",
    "en-US": "Return to Homepage",
  },

  //SUCCESS SNACKBAR
  "success-upload": {
    "it-IT": "Caricamento riuscito!",
    "en-US": "Successful upload!",
  },
  "success-edit": {
    "it-IT": "Modifiche salvate con successo!",
    "en-US": "Changes saved successfully!",
  },
  //UPLOAD IMAGE
  "upload-title": {
    "it-IT": "Carica la tua Foto",
    "en-US": "Upload your Photo",
  },
  "upload-upload": {
    "it-IT": "Carica Immagine",
    "en-US": "Upload Image",
  },
  "upload-change": {
    "it-IT": "Cambia Immagine",
    "en-US": "Change Image",
  },
  "upload-confirm": {
    "it-IT": "Conferma",
    "en-US": "Confirm",
  },
  "upload-photo": {
    "it-IT": "Carica Foto",
    "en-US": "Upload Photo",
  },
  "upload-take": {
    "it-IT": "Scatta Foto",
    "en-US": "Take Pictures",
  },
  "upload-remove": {
    "it-IT": "Rimuovi Foto",
    "en-US": "Remove Photo",
  },
  "upload-success": {
    "it-IT": "Caricamento riuscito!",
    "en-US": "Upload successful!",
  },
  "delete-success": {
    "it-IT": "Eliminazione riuscita!",
    "en-US": "Successful deletion!",
  },
  "modify-profile": {
    "it-IT": "Modifica il tuo profilo",
    "en-US": "Edit your profile",
  },
  "modify-profile-button": {
    "it-IT": "Modifica profilo",
    "en-US": "Edit profile",
  },
  "modify-appearance-button": {
    "it-IT": "Modifica grafica",
    "en-US": "Edit appearance",
  },
  "link-your-nfc": {
    "en-US": "Link your NFC Card!",
    "it-IT": "Collega la tua card NFC!",
  },
  nfc: {
    success: {
      "it-IT": "NFC collegato correttamente",
      "en-US": "NFC Linked Successfully",
    },
    title: {
      "it-IT": "Collega la tua Card NFC",
      "en-US": "Link your card NFC",
    },
    title2: {
      "it-IT": "Carta NFC Attivata con Successo",
      "en-US": "Card NFC Successfully Actived",
    },
    code: {
      "it-IT":
        "Il Codice NFC è il codice nella tua barra di ricerca quando scansioni la card NFC",
      "en-US":
        "The NFC Code is the code in your search bar when you scan the NFC card",
    },
    pin: {
      "it-IT": "Il PIN è il codice che trovi nella brochure",
      "en-US": "The PIN is the code you will find in the brochure",
    },
    link: {
      "it-IT": "Collega card",
      "en-US": "Link your card",
    },
    unlink: {
      "it-IT": "Scollega card",
      "en-US": "Disconnect your card",
    },
  },
  tooltips: {
    "change-language": {
      "it-IT": "Cambia lingua",
      "en-US": "Change language",
    },
    "modify-profile": {
      "it-IT": "Modifica profilo",
      "en-US": "Modify profile",
    },
    overview: {
      "it-IT": "Overview",
      "en-US": "Overview",
    },
    back: {
      "it-IT": "Indietro",
      "en-US": "Go back",
    },
    "modify-avatar": {
      "it-IT": "Modifica immagine",
      "en-US": "Edit picture",
    },
  },
  // CROP IMAGE
  crop_image: {
    crop: {
      "it-IT": "Ritaglia",
      "en-US": "Crop",
    },
    cancel: {
      "it-IT": "Cancella",
      "en-US": "Cancel",
    },
    rotation: {
      "it-IT": "Ruota",
      "en-US": "Rotation",
    },
    reset: {
      "it-IT": "Resetta",
      "en-US": "Reset",
    },
    bgcolor: {
      "it-IT": "Colore di sfondo",
      "en-US": "Background color",
    },
  },
  //Confirm deletion
  "delete-confirmation-title": {
    "it-IT": "Conferma Eliminazione",
    "en-US": "Confirm Deletion",
  },
  "delete-confirmation-description": {
    "it-IT": "Sei sicuro di voler eliminare questo elemento?",
    "en-US": "Are you sure you want to delete this item?",
  },
  "delete-confirmation-cancel": {
    "it-IT": "Annulla",
    "en-US": "Cancel",
  },
  "delete-confirmation-confirm": {
    "it-IT": "Conferma",
    "en-US": "Confirm",
  },
  snackbars: {
    "already-logged-in": {
      "it-IT": "Sei già autenticato.",
      "en-US": "You are already logged in.",
    },
    "image-too-big": {
      "it-IT": "Immagine troppo grande.",
      "en-US": "Image is too big.",
    },
    "file-too-big": {
      "it-IT": "File troppo grande.",
      "en-US": "File is too big.",
    },
    "pdf-too-big": {
      "it-IT": "Il PDF da caricare non può superare i 7MB.",
      "en-US": "The PDF to be uploaded cannot exceed 7MB.",
    },
    "not-load-info": {
      "it-IT": "Errore nel caricamento dei dati.",
      "en-US": "Error loading data.",
    },
  },
  landing_page: {
    register_free: {
      "it-IT": "Registrati",
      "en-US": "Register",
    },
    login: {
      "it-IT": "Accedi",
      "en-US": "Login",
    },
    hero_text: {
      "it-IT":
        "Scopri il primo link in bio completamente Made in Italy. Un unico link per aiutarti a condividere tutto ciò che crei, curi e vendi dai tuoi profili Instagram, TikTok, Twitter, YouTube e altri social media.",
      "en-US":
        "Discover the first completely Made in Italy link in bio. A single link to help you share everything you create, care for and sell from your Instagram, TikTok, Twitter, YouTube profiles and other social media.",
    },
    get_your_link: {
      "it-IT": "Ottieni il tuo link",
      "en-US": "Get your link",
    },
    dark_section_title: {
      "it-IT": "Crea e personalizza il tuo Hellolink® in qualche minuto",
      "en-US": "Create and customize your Hellolink® in minutes",
    },
    dark_section_subtitle: {
      "it-IT":
        "Collega il tuo Instagram, TikTok, Twitter, sito web, negozio, video, musica, podcast, eventi e molto altro. ",
      "en-US":
        "Connect your Instagram, TikTok, Twitter, website, store, videos, music, podcasts, events and much more.",
    },
    light_section_title: {
      "it-IT":
        "Condividi il tuo Hellolink® dal tuo Instagram, TikTok, Twitter a altre bio",
      "en-US":
        "Share your Hellolink® from your Instagram, TikTok, Twitter to other bios",
    },
    light_section_subtitle: {
      "it-IT":
        "Aggiungi il tuo URL Hellolink® univoco a tutte le piattaforme e i luoghi in cui trovi il tuo target. Quindi utilizza il tuo codice QR per indirizzare il tuo traffico offline online.",
      "en-US":
        "Add your unique Hellolink® URL to all the platforms and places where you find your target. Then use your QR code to direct your offline traffic online.",
    },
    faq_title: {
      "it-IT": "Hai domande?",
      "en-US": "Any questions?",
    },
  },
  modify_appearance: {
    background: {
      "it-IT": "Sfondo",
      "en-US": "Background",
    },
    flat_color: {
      "it-IT": "Colore Piatto",
      "en-US": "Flat Color",
    },
    gradient: {
      "it-IT": "Gradiente",
      "en-US": "Gradient",
    },
    color: {
      "it-IT": "Colore",
      "en-US": "Color",
    },
    hover: {
      "it-IT": "Hover",
      "en-US": "Hover",
    },
    text: {
      "it-IT": "Testo",
      "en-US": "Text",
    },
    text_color: {
      "it-IT": "Colore testo",
      "en-US": "Text color",
    },
    stroke: {
      "it-IT": "Bordo",
      "en-US": "Stroke",
    },
    preview: {
      "it-IT": "Anteprima",
      "en-US": "Just a preview!",
    },
    button: {
      "it-IT": "Pulsante",
      "en-US": "Button",
    },
    reset: {
      "it-IT": "Ripristina",
      "en-US": "Reset",
    },
    reset_all: {
      "it-IT": "Ripristina tutto",
      "en-US": "Reset all",
    },
    modal_title: {
      "it-IT": "Hai delle modifiche non salvate!",
      "en-US": "You have unsaved changes!",
    },
    modal: {
      "it-IT":
        "Hai delle modifiche non salvate. Vuoi salvare le modifiche prima di uscire?",
      "en-US":
        "You have unsaved changes. Do you want to save your changes before exiting?",
    },
    leave: {
      "it-IT": "Esci",
      "en-US": "Leave",
    },
    cancel: {
      "it-IT": "Annulla",
      "en-US": "Cancel",
    },
    save: {
      "it-IT": "Salva",
      "en-US": "Save",
    },
  },
  faq: {
    1: {
      title: {
        "it-IT":
          "Che cos'è Hello Link e come funziona il suo servizio di profilazione?",
        "en-US": "What is Hello Link and how does its profiling service work?",
      },
      text: {
        "it-IT":
          "Hello Link è uno strumento innovativo progettato per semplificare la gestione delle informazioni personali online. Con Hello Link, puoi creare il tuo profilo personale che ti consente di raccogliere in un unico posto un numero illimitato di link e di creare schede contatto con dati salvabili direttamente nella rubrica dei tuoi interlocutori. Questo strumento ti offre la comodità di avere tutto ciò di cui hai bisogno per presentarti, in un unico posto",
        "en-US":
          "Hello Link is an innovative tool designed to simplify the management of personal information online. With Hello Link, you can create your personal profile that allows you to collect an unlimited number of links in one place and create contact cards with data that can be saved directly in the address book of your interlocutors. This tool offers you the convenience of having everything you need to present yourself in one place",
      },
    },
    2: {
      title: {
        "it-IT": "Posso modificare il mio username nel profilo Hello Link?",
        "en-US": "Can I change my username in the Hello Link profile?",
      },
      text: {
        "it-IT":
          "Assolutamente sì! Ti offriamo la flessibilità di personalizzare il tuo profilo come desideri. Per modificare il tuo username, (o anche nome e cognome o biografia) basta accedere alle impostazioni del tuo account facendo clic sull'icona del pennino in alto a sinistra. Da lì, avrai accesso per modificare le informazioni del tuo profilo in modo rapido e semplice.",
        "en-US":
          "Absolutely yes! We offer you the flexibility to customize your profile as you wish. To change your username, (or even name and surname or biography) just access the settings of your account by clicking on the pen icon at the top left. From there, you will have access to edit your profile information quickly and easily.",
      },
    },
    3: {
      title: {
        "it-IT": "Posso aggiornare i link presenti nel mio profilo Hello Link?",
        "en-US": "Can I update the links in my Hello Link profile?",
      },
      text: {
        "it-IT":
          "Certo! Con Hello Link, hai il controllo completo sui link presenti nel tuo profilo. Per aggiornare un link, basta cliccare sul pulsante corrispondente e modificare il link e, se necessario, anche il nome del pulsante e l'icona associata. Ricorda di salvare le modifiche per renderle effettive e aggiornare così il tuo profilo con i contenuti più recenti.",
        "en-US":
          "Of course! With Hello Link, you have complete control over the links in your profile. To update a link, just click on the corresponding button and edit the link and, if necessary, also the name of the button and the associated icon. Remember to save the changes to make them effective and thus update your profile with the most recent content.",
      },
    },
    4: {
      title: {
        "it-IT":
          "Posso modificare i dettagli delle schede contatto nel mio profilo Hello Link?",
        "en-US":
          "Can I edit the details of the contact cards in my Hello Link profile?",
      },
      text: {
        "it-IT":
          "Assolutamente! Con Hello Link, puoi facilmente aggiornare i dati presenti nella scheda contatto del tuo profilo. Basta fare clic sulla scheda contatto che desideri modificare e sostituire i dati presenti con quelli aggiornati. Non dimenticare di salvare le modifiche per assicurarti che le informazioni siano aggiornate e corrette. Con Hello Link, mantenere aggiornato il tuo profilo è facile e veloce.",
        "en-US":
          "Absolutely! With Hello Link, you can easily update the data in the contact card of your profile. Just click on the contact card you want to edit and replace the existing data with the updated ones. Don't forget to save the changes to make sure the information is up to date and correct. With Hello Link, keeping your profile up to date is easy and fast.",
      },
    },
    5: {
      title: {
        "it-IT":
          "Ci sono costi aggiuntivi associati all'utilizzo di Hello Link?",
        "en-US":
          "Are there any additional costs associated with using Hello Link?",
      },
      text: {
        "it-IT":
          "Assolutamente no! Con Hello Link, la creazione del tuo profilo è completamente gratuita e non ci sono costi nascosti. Inoltre, tutte le modifiche al tuo profilo sono illimitate e gratuite, garantendo un'esperienza senza complicazioni e senza spese aggiuntive.",
        "en-US":
          "Absolutely not! With Hello Link, creating your profile is completely free and there are no hidden costs. Furthermore, all changes to your profile are unlimited and free, ensuring a hassle-free experience without any additional costs.",
      },
    },
    6: {
      title: {
        "it-IT":
          "Quando aggiorno il mio profilo Hello Link, l'aggiornamento si riflette automaticamente sulla mia card?",
        "en-US":
          "When I update my Hello Link profile, does the update automatically reflect on my card?",
      },
      text: {
        "it-IT":
          "Assolutamente sì! Con Hello Link, l'aggiornamento del tuo profilo è sincronizzato in tempo reale con la tua card. Quando apporti modifiche al tuo profilo Hello Link e le salvi, la tua card si aggiornerà automaticamente senza la necessità di ulteriori procedure. Questo garantisce che le informazioni sul tuo profilo siano sempre aggiornate e precise.",
        "en-US":
          "Absolutely yes! With Hello Link, updating your profile is synchronized in real time with your card. When you make changes to your Hello Link profile and save them, your card will automatically update without the need for additional procedures. This ensures that the information on your profile is always up to date and accurate.",
      },
    },
    7: {
      title: {
        "it-IT": "Posso personalizzare graficamente il mio profilo Hello Link?",
        "en-US": "Can I customize the graphics of my Hello Link profile?",
      },
      text: {
        "it-IT":
          "Certamente! Con Hello Link, ti offriamo la possibilità di personalizzare graficamente il tuo profilo secondo le tue preferenze. Puoi modificare l'immagine del profilo, il colore di sfondo e il colore dei pulsanti con facilità. Basta cliccare sull'icona della pennina in alto a sinistra all'interno del tuo profilo per accedere alle opzioni di personalizzazione grafica. Con Hello Link, puoi esprimere la tua creatività e rendere il tuo profilo unico e distintivo.",
        "en-US":
          "Certainly! With Hello Link, we offer you the possibility to customize the graphics of your profile according to your preferences. You can easily change the profile picture, background color, and button color. Just click on the pen icon at the top left inside your profile to access the graphic customization options. With Hello Link, you can express your creativity and make your profile unique and distinctive.",
      },
    },
  },
};
